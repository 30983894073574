@import "../../../../scss/variables";

.search {
  position: relative;
  max-width: 666px;
  width: 100%;
  flex: 1 1 auto;
  border-right: 2px solid #f8f8f8;
  padding-right: 12px;
  &.active {
    @media (max-width: 600px) {
      position: absolute;
      left: 0;
      padding: 0 32px;
      border-right: none;
    }
    @media (max-width: 480px) {
      padding: 0 16px;
    }
    .search__result {
      display: block;
    }
  }
  &__form {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    path {
      fill: $text;
    }
  }

  &__input {
    flex: 1 1 auto;
    width: 100%;
    margin: 0 24px;
    font-size: 20px;
    font-weight: 500;
    padding: 23px 0;
    @media (max-width: 768px) {
      margin: 0 0 0 15px;
    }
    @media (max-width: 600px) {
      padding: 13px 0;
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }

  &__btn {
    font-size: 20px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__result {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #fff;
    border-top: 2px solid #f8f8f8;
    padding: 24px;
    @media (max-width: 480px) {
      padding: 16px;
    }
  }

  &__result-item {
    cursor: pointer;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 12px;
  }

  &__result-img {
    width: 36px;
    height: 36px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__result-text {
    font-family: $title;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.searchCount {
  font-size: 16px;
  font-weight: 500;
  color: $orange;
  margin-top: 12px;
  white-space: nowrap;
}

.searchResultMin {
  &__inner {
    display: flex;
    .searchCount {
      margin-top: 0;
      position: absolute;
      right: 50px;
    }
    @media (max-width: 990px) {
      flex-direction: column;
      .searchCount {
        margin-top: 32px;
        position: relative;
        right: 0;
      }
    }
  }
  &__list {
    flex: 1 1 auto;
  }

  &__item {
    margin-bottom: 24px;
  }

  &__link {
    display: block;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease 0s;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    @media (min-width: 990px) {
      &:hover {
        color: $orange;
      }
    }
  }

  &__sub {
    padding-left: 16px;
  }
}