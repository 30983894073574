@import "../../scss/variables";

.footer {
  background-color: $text;
  padding: 32px 0 50px 0;
  @media (max-width: 768px) {
    padding: 32px 0;
  }
  &__menu {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  }

  &__item {
    font-family: $title;
    text-align: center;
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .4px;
    @media (max-width: 768px) {
      text-align: left;
    }
  }

  &__logo {
    position: relative;
    @media (max-width: 768px) {
      order: 8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
      margin-top: 20px;
    }
  }

  &__copyright {
    position: absolute;
    bottom: -20px;
    left: 0;
    @media (max-width: 768px) {
      position: relative;
      bottom: auto;
      left: auto;
    }
  }
  .container {
    max-width: 1200px;
  }
  .logo__link {
    letter-spacing: 0;
  }
}
