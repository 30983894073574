.radio {
  position: relative;
  cursor: pointer;
  display: flex;
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    &:checked + .radio__style::before {
      content: "";
      width: 6px;
      height: 6px;
      background-color: $orange;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    &:disabled + .radio__style {
      background-color: #f2f2f2;
      border: 2px solid #e0e0e0;
    }
    &:checked:disabled + .radio__style::before {
      opacity: 0.5;
    }
  }

  &__style {
    flex-shrink: 0;
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid $grayLight;
    border-radius: 50%;
    margin-right: 10px;
  }
}
