@import "../../../../scss/variables";

.popup {
  &__head {
    border-radius: 4px 4px 0 0;
    background-color: $f8;
    margin: -32px -32px 0 -32px;
    padding: 32px;
    @media (max-width: 480px) {
      margin: -16px -16px 0 -16px;
      padding: 16px;
    }
  }
  &__title {
    font-family: $title;
    font-size: 24px;
    line-height: 28px;
    @media (max-width: 480px) {
      font-size: 26px;
    }
  }
  &__close {
    font-size: 0;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 30;
    transition: all 0.4s ease 0s;
    background-size: cover;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3Ljk5ODkgMTYuNDk5MkwxNi40OTkyIDE4TDguOTk5NDYgMTAuNTAwM0wxLjQ5OTc0IDE4TDAgMTYuNTAwM0w3LjQ5ODY5IDkuMDAwNTNMMCAxLjUwMDc5TDEuNDk5NzQgMEw4Ljk5ODQyIDcuNDk5NzRMMTYuNDk5MiAwLjAwMTA1MTk2TDE3Ljk5ODkgMS41MDA3OUwxMC41MDAyIDguOTk5NDZMMTcuOTk4OSAxNi40OTkyWiIgZmlsbD0iIzExMTExMSIvPgo8L3N2Zz4K");
    //background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3Ljk5ODkgMTYuNDk5MkwxNi40OTkyIDE4TDguOTk5NDYgMTAuNTAwM0wxLjQ5OTc0IDE4TDAgMTYuNTAwM0w3LjQ5ODY5IDkuMDAwNTNMMCAxLjUwMDc5TDEuNDk5NzQgMEw4Ljk5ODQyIDcuNDk5NzRMMTYuNDk5MiAwLjAwMTA1MTk2TDE3Ljk5ODkgMS41MDA3OUwxMC41MDAyIDguOTk5NDZMMTcuOTk4OSAxNi40OTkyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
    @media (min-width: 990px) {
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  &__descr{
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
  }
}