* {
	padding: 0;
	margin: 0;
	border: 0;
}
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:focus,
:active {
	outline: none;
}
a:focus,
a:active {
	outline: none;
}
::placeholder {
	color: rgb(155, 155, 155);
}
aside,
nav,
footer,
header,
section {
	display: block;
}
html,
body {
	//height: 100%;
	min-width: 320px;
}
body {
	//text-rendering: optimizeLegibility;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
}
input,
button,
textarea {
	font-family: inherit;
	font-size: inherit;
}
input::-ms-clear {
	display: none;
}
input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
button {
	cursor: pointer;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
a{
	color: inherit;
}
a,
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
ul li {
	list-style: none;
}
img {
	vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}
div{
	display: block;
}
.hidden {
	display: none !important;
}
//-webkit-overflow-scrolling: touch;
//animation-play-state: paused !important;
