@import "../../scss/variables.scss";

.notice {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12px;
  background-color: $orange;
  border: 2px solid $text;
  border-radius: 100px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 500;
  min-width: 20px;
  min-height: 20px;

  &__button {
    right: -6px;
    color: #fff;
    background-color: $red !important;
    border: 2px solid #fff !important;
  }
}
