@import "../../scss/variables.scss";

.header {
  display: flex;
  align-items: center;
  background-color: $text;
  color: #fff;
  height: 72px;
  position: relative;
  z-index: 11;
  @media (max-width: 990px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 52px;
  }
  &__top {
  }

  &__top--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    @media (max-width: 480px) {
      margin-right: 32px;
    }
  }

  &__basic {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    @media (max-width: 990px) {
      display: none;
    }
  }

  &__basic-item {
    margin-left: 64px;
    @media (max-width: 1366px) {
      margin-left: 25px;
    }
  }

  &__addit {
    display: flex;
    align-items: center;
    //flex: 1 1 auto;
    margin-left: 40px;
    border-left: 2px solid #1e293d;
    @media (max-width: 990px) {
      display: none;
    }
  }

  &__addit-item {
    position: relative;
    margin-left: 40px;
    &:not(.refunds) {
      padding: 24px 0;
    }
    @media (max-width: 1366px) {
      margin-left: 25px;
    }
    .notice{
      top: calc(50% - 20px);
      left: calc(100% - 5px);
    }
  }

  &__interaction {
    display: flex;
    align-items: center;
    @media (max-width: 480px) {
      justify-content: space-between;
      flex: 1 1 auto;
    }
  }

  &__interaction-item {
    margin-left: 50px;
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
      margin-left: 30px;
    }
    @media (max-width: 480px) {
      margin-left: 0;
    }
  }

}

.headerBottom {
  display: flex;
  align-items: center;
  height: 72px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10;
  transition: all 0.2s ease 0s;
  margin-bottom: auto;
  @media (max-width: 990px) {
    position: fixed;
    top: 72px;
    left: 0;
    width: 100%;
    &.hide{
      transform: translateY(-100%);
      .search__result{

      }
    }
  }
  @media (max-width: 600px) {
    top: 52px;
    height: 52px;
  }
  .headerBottom__container {
    max-width: 1200px;
  }
  &.show-company {
    .headerBottom__company{
      @media (min-width: 990px) {
        display: block;
      }
    }
    .headerBottom__container {
      flex: 1 1 auto;
      max-width: 100%;
      padding: 0 0 0 32px;
      @media (max-width: 990px) {
        padding: 0 32px;
      }
      @media (max-width: 480px) {
        padding: 0 16px;
      }
    }
  }
  &--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__extra {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
  }

  &__extra-item {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
    margin: 0 25px;
    white-space: nowrap;
    @media (max-width: 1200px) {
      margin: 0 15px;
    }
    @media (max-width: 990px) {
      margin: 0 0 0 32px;
    }
    @media (max-width: 768px) {
      margin: 0 0 0 16px;
    }
    @media (max-width: 600px) {
      &:last-child {
        display: none;
      }
    }
  }

  &__extra-icon {
    @media (max-width: 990px) {
      display: none;
    }
  }

  &__extra-text {
    color: $orange;
    font-weight: 500;
    @media (min-width: 990px) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__company{
    display: none;
    @media (max-width: 990px) {
      display: none !important;
    }
  }
}



.catalogs {
  cursor: pointer;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  &__icon {
    width: 22px;
    height: 18px;
    span {
      display: block;
      width: 22px;
      height: 2px;
      background-color: #fff;
      margin: 6px 0;
    }
    &::after {
      display: block;
      content: "";
      width: 22px;
      height: 2px;
      background-color: #fff;
    }
    &::before {
      display: block;
      content: "";
      width: 22px;
      height: 2px;
      background-color: #fff;
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &__text {
  }
}
.city {
  position: relative;
  &__link {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  &__text {
    border-bottom: 1px dashed #fff;
  }

  &__question {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translate(-50%, 0);
    padding: 24px;
    max-width: 260px;
    width: max-content;
    z-index: 5;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  }

  &__question-title {
    font-size: 16px;
    color: $black;
    text-align: center;
  }

  &__question-btn {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  button {
    padding: 8px 16px !important;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.refunds {
  position: relative;
  &:hover .refunds__menu {
    display: block;
  }
  &__link {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
    padding: 24px 0;
  }

  &__link-text {
  }

  &__link-icon {
    path {
      fill: #fff;
    }
  }

  &__menu {
    display: none;
    position: absolute;
    top: 97%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 146px;
    border-top: 2px solid $orange;
    z-index: 10;
  }

  &__menu-item {
    background-color: $text;
    display: block;
    padding: 10px 12px;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 2px solid #2c313a;
    }
  }
}
.chats {
  position: relative;
  &__icon {
  }
  .notice {
    left: 50%;
  }
}
.basket {
  position: relative;
  &__icon {
    width: 18px;
    height: 16px;
    path{
      fill: #fff;
    }
  }
  .notice {
    left: 50%;
  }
}

.user {
  position: relative;
  &:hover .user__menu {
    display: block;
  }
  &__link {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
    padding: 24px 0;
    height: 72px;
    @media (max-width: 600px) {
      height: auto;
      padding: 0;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  &__arrow {
    path {
      fill: #fff;
    }
    @media (max-width: 990px) {
      display: none;
    }
  }

  &__menu {
    display: none;
    position: absolute;
    top: 97%;
    right: -5px;
    width: 145px;
    border-top: 2px solid $orange;
    z-index: 10;
  }

  &__menu-item {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    align-items: center;
    background-color: $text;
    padding: 10px 12px;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 2px solid #2c313a;
    }
  }

  &__menu-icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  &__menu-text {
  }
}
.burger {
  display: none;
  @media (max-width: 990px) {
    display: block;
    cursor: pointer;
    position: relative;
    width: 18px;
    height: 16px;
    z-index: 5;
    span {
      top: calc(50% - 1px);
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transition: all 0.2s ease 0s;
      &:first-child {
        top: 0;
      }
      &:last-child {
        top: auto;
        bottom: 0;
      }
    }
    &.active {
      span {
        transform: scale(0);
        &:first-child {
          transform: rotate(-45deg);
          top: calc(50% - 1px);
        }
        &:last-child {
          transform: rotate(45deg);
          bottom: calc(50% - 1px);
        }
      }
    }
  }
}

.company {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  width: 200px;
  height: 72px;
  padding: 0 20px;
  background-color: #242a35;
  color: #fff;
  font-weight: 500;
  &.open {
    .company__details {
      display: block;
    }
    .company__total-icon {
      transform: rotate(180deg);
    }
  }
  &__title {
    font-size: 14px;
    margin-bottom: 3px;
  }

  &__total {
    cursor: pointer;
    display: inline-grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    align-items: center;
  }

  &__total-text {
    text-decoration: underline;
  }

  &__total-icon {
    transition: all 0.2s ease 0s;
  }

  &__status {
    margin-top: 3px;
    font-size: 14px;
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
    justify-content: start;
  }

  &__status--yellow {
    color: $yellow;
  }

  &__status-icon {
  }

  &__status-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__details {
    display: none;
    position: absolute;
    overflow: auto;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px 0;
    background-color: #242a35;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
  }

  &__details-item {
    padding: 8px 20px;
    border-bottom: 2px solid $text;
  }

  &__details-inner:not(:last-child) {
    margin-bottom: 8px;
  }

  &__details-title {
    font-size: 14px;
    color: $gray;
    margin-bottom: 2px;
  }

  &__select {
    margin: 8px 0;
    .select__title {
      color: #fff;
      background-color: $text;
      border: none;
    }
    .select__value {
      height: 32px;
      &::before {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjcxODc1IDUuODc1QzUuODc1IDYuMDMxMjUgNi4wOTM3NSA2LjAzMTI1IDYuMjUgNS44NzVMMTAuODc1IDEuMjgxMjVDMTEuMDMxMiAxLjE1NjI1IDExLjAzMTIgMC45MDYyNSAxMC44NzUgMC43NUwxMC4yNSAwLjE1NjI1QzEwLjEyNSAwIDkuODc1IDAgOS43MTg3NSAwLjE1NjI1TDYgMy44NDM3NUwyLjI1IDAuMTU2MjVDMi4wOTM3NSAwIDEuODc1IDAgMS43MTg3NSAwLjE1NjI1TDEuMDkzNzUgMC43NUMwLjkzNzUgMC45MDYyNSAwLjkzNzUgMS4xNTYyNSAxLjA5Mzc1IDEuMjgxMjVMNS43MTg3NSA1Ljg3NVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=")
        no-repeat center;
      }
    }
    .select__options {
      color: #fff;
      background-color: $text;
      border: none;
    }
    .select__option {
      @media (min-width: 990px) {
        &:hover {
          background-color: lighten($text, 5%);
          color: #fff;
        }
      }
      &.active {
        background-color: lighten($text, 5%);
        color: #fff;
      }
    }
  }

  &__details-delay {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    align-items: center;
    margin-bottom: 8px;
  }

  &__details-icon {
    fill: #C4C4C4
  }

  &__details-text {
  }
}

.menuMob {
  display: none;
  @media (max-width: 990px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: $black;
    overflow: auto;
    padding: 72px 0 20px 0;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 600px) {
      padding: 52px 0 20px 0;
    }
    &.active {
      display: block;
    }
    &__company {
      margin-top: 2px;
      padding: 10px 32px;
      width: 100%;
      background-color: $blackLight;
      height: auto;
      @media (max-width: 480px) {
        padding: 10px 16px;
      }
    }
    .company__details {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      background-color: #fff;
      margin: 10px -32px -10px -32px;
      padding: 10px 0 0 0;
      color: $black;
      transition: all 0.2s ease 0s;
      z-index: 11;
      @media (max-width: 480px) {
        margin: 10px -16px -10px -16px;
      }
    }
    .company__details-item {
      border-bottom: 2px solid $grayLight;
      padding: 8px 32px;
      @media (max-width: 480px) {
        padding: 8px 16px;
      }
    }
    .company__select .select__title,
    .company__select .select__options{
      color: inherit;
      background-color: #fff;
      border: 1px solid #c4c4c4;
    }

    .company__select .select__option.active {
      color: $orange;
      background-color: #fff;
    }
    .select__value::before {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjcxODc1IDUuODc1QzUuODc1IDYuMDMxMjUgNi4wOTM3NSA2LjAzMTI1IDYuMjUgNS44NzVMMTAuODc1IDEuMjgxMjVDMTEuMDMxMiAxLjE1NjI1IDExLjAzMTIgMC45MDYyNSAxMC44NzUgMC43NUwxMC4yNSAwLjE1NjI1QzEwLjEyNSAwIDkuODc1IDAgOS43MTg3NSAwLjE1NjI1TDYgMy44NDM3NUwyLjI1IDAuMTU2MjVDMi4wOTM3NSAwIDEuODc1IDAgMS43MTg3NSAwLjE1NjI1TDEuMDkzNzUgMC43NUMwLjkzNzUgMC45MDYyNSAwLjkzNzUgMS4xNTYyNSAxLjA5Mzc1IDEuMjgxMjVMNS43MTg3NSA1Ljg3NVoiIGZpbGw9IiM5Njk2OTYiLz4KPC9zdmc+Cg==)
      no-repeat center;
    }
    &__list {
      padding: 0 32px;
      color: #fff;
      @media (max-width: 480px) {
        padding: 0 16px;
      }
    }

    &__item {
      margin-top: 24px;
    }

    &__link {
    }
    &__exit {
      display: inline-grid;
      grid-auto-flow: column;
      column-gap: 8px;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        fill: currentColor;
      }
    }
  }
}
