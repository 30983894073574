@import "../../../../../../../scss/popup";

.cityes {
  max-width: 480px !important;
  &__input {
    margin: 16px 0;
  }

  &__list {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3,1fr);
    margin: 0 -5px;
    @media (max-width: 480px) {
      grid-template-columns: repeat(2,1fr);
    }
  }

  &__item {
    cursor: pointer;
    padding: 3px 5px;
    transition: all 0.2s ease 0s;
    @media (min-width: 990px) {
      &:hover {
        color: $orange;
      }
    }
  }
}