@import "variables";

.popup {
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  padding: 30px 10px;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: visibility 0.2s ease 0s;
  visibility: hidden;
  &::before {
    content: "";
    background-color: rgba($text, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease 0s;
  }
  &.show {
    visibility: visible;
    overflow: auto;
    &::before {
      opacity: 1;
    }
    .popup__body {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.active {
    overflow: auto;
    visibility: visible;
    &::before {
      opacity: 1;
    }
    .popup__body {
      opacity: 1;
      transition: all 0.2s ease 0s;
      transform: scale(1);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__body {
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.2s ease 0s;
    background-color: #fff;
    padding: 32px;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
    @media (max-width: 480px) {
      padding: 16px;
    }
  }
  &__head {
    border-radius: 4px 4px 0 0;
    background-color: $f8;
    margin: -32px -32px 0 -32px;
    padding: 32px;
    @media (max-width: 480px) {
      margin: -16px -16px 0 -16px;
      padding: 16px;
    }
  }
  &__close {
    font-size: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 30;
    transition: all 0.4s ease 0s;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3Ljk5ODkgMTYuNDk5MkwxNi40OTkyIDE4TDguOTk5NDYgMTAuNTAwM0wxLjQ5OTc0IDE4TDAgMTYuNTAwM0w3LjQ5ODY5IDkuMDAwNTNMMCAxLjUwMDc5TDEuNDk5NzQgMEw4Ljk5ODQyIDcuNDk5NzRMMTYuNDk5MiAwLjAwMTA1MTk2TDE3Ljk5ODkgMS41MDA3OUwxMC41MDAyIDguOTk5NDZMMTcuOTk4OSAxNi40OTkyWiIgZmlsbD0iIzExMTExMSIvPgo8L3N2Zz4K");
    //background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3Ljk5ODkgMTYuNDk5MkwxNi40OTkyIDE4TDguOTk5NDYgMTAuNTAwM0wxLjQ5OTc0IDE4TDAgMTYuNTAwM0w3LjQ5ODY5IDkuMDAwNTNMMCAxLjUwMDc5TDEuNDk5NzQgMEw4Ljk5ODQyIDcuNDk5NzRMMTYuNDk5MiAwLjAwMTA1MTk2TDE3Ljk5ODkgMS41MDA3OUwxMC41MDAyIDguOTk5NDZMMTcuOTk4OSAxNi40OTkyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
    @media (min-width: 990px) {
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  &__title {
    font-family: $title;
    font-size: 24px;
    line-height: 28px;
    @media (max-width: 480px) {
      font-size: 26px;
    }
  }

  &__descr {
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
  }

  &__text {
    margin-top: 12px;
  }

  &__message {
    background-color: $redLight;
    margin: 24px -32px;
    padding: 12px 32px;
    font-weight: 500;
    @media (max-width: 480px) {
      margin: 24px -16px;
    }
  }

  &__btn {
    width: 100%;
    padding: 14px 24px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__form {
    width: 100%;
    margin: 20px 0 0 0;
    input {
      width: 100%;
    }
  }
  &__radio {
    margin: 24px 0;
    .radio {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  &__success {
    display: none;
    text-align: center;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    .undoDelete {
      color: $gray;
      cursor: pointer;
    }
  }
  &__instruction {
    margin-top: 24px;
  }
  &__pricelist {
    .popup__body {
      max-width: 1140px;
    }
  }
  &__defect {
    .popup__body {
      max-width: 600px;
    }
  }
  &__timing {
    .popup__body {
      max-width: 990px;
    }
  }
}

.defect {
  .popup__body {
    max-width: 600px;
  }
  &__slider {
    margin-top: 32px;
  }

  &__sliderPhoto {
  }

  &__sliderMiniature {
    position: relative;
    margin-top: 24px;
    padding: 0 32px;
    .defect__item{
      cursor: pointer;
      transition: all 0.2s ease 0s;
      @media (min-width: 990px) {
        &:hover {
          opacity: .7;
        }
      }
    }
    .swiper-btn-next,
    .swiper-btn-prev{
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      width: 24px;
      height: 24px;
      z-index: 5;
    }
    .swiper-btn-next{
      right: 0;
    }
    .swiper-btn-prev{
      left: 0;
    }
    .swiper-button-disabled{
      cursor: default;
      opacity: .4;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    height: 100%;
    &::before {
      content: "";
      padding-top: 100%;
      display: block;
      background-color: #f8f8f8;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}