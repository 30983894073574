@import "../../scss/variables.scss";

.logo {
  position: relative;

  &__link {
    color: $grayLight;
    display: block;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #3e4249;
    border-radius: 4px;
    padding: 10px 15px;
  }

  .copyright {
    position: absolute;
    bottom: -20px;
    left: 0;
  }

}