@import "variables";

.status {
  position: relative;
  padding-left: 20px;
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    left: 0;
    top: 4px;
    border-radius: 100px;
  }
}
.status--green {
  &::after {
    background-color: $green;
  }
}
.status--yellow {
  &::after {
    background-color: $yellow;
  }
}
.status--red {
  &::after {
    background-color: $red;
  }
}
.status--green-red {
  &::after {
    background-color: $green;
    transform: translate(-2px, 0);
  }
  &::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 3px;
    transform: translate(2px, 0);
    border-radius: 100px;
    background-color: $red;
    border: 2px solid #fff;
    z-index: 1;
  }
}

.status--done{
  &::after{
    content: '';
    width: 16px;
    height: 16px;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMC4xODc1QzIuNzg5MDYgMC4xODc1IDAuMTg3NSAyLjgxMjUgMC4xODc1IDZDMC4xODc1IDkuMjEwOTQgMi43ODkwNiAxMS44MTI1IDYgMTEuODEyNUM5LjE4NzUgMTEuODEyNSAxMS44MTI1IDkuMjEwOTQgMTEuODEyNSA2QzExLjgxMjUgMi44MTI1IDkuMTg3NSAwLjE4NzUgNiAwLjE4NzVaTTYgMS4zMTI1QzguNTc4MTIgMS4zMTI1IDEwLjY4NzUgMy40MjE4OCAxMC42ODc1IDZDMTAuNjg3NSA4LjYwMTU2IDguNTc4MTIgMTAuNjg3NSA2IDEwLjY4NzVDMy4zOTg0NCAxMC42ODc1IDEuMzEyNSA4LjYwMTU2IDEuMzEyNSA2QzEuMzEyNSAzLjQyMTg4IDMuMzk4NDQgMS4zMTI1IDYgMS4zMTI1Wk05LjI4MTI1IDQuMzgyODFMOC43NDIxOSAzLjg0Mzc1QzguNjQ4NDQgMy43MjY1NiA4LjQ2MDk0IDMuNzI2NTYgOC4zNDM3NSAzLjg0Mzc1TDUuMDM5MDYgNy4xMjVMMy42MzI4MSA1LjcxODc1QzMuNTE1NjIgNS42MDE1NiAzLjM1MTU2IDUuNjAxNTYgMy4yMzQzOCA1LjcxODc1TDIuNjk1MzEgNi4yMzQzOEMyLjYwMTU2IDYuMzUxNTYgMi42MDE1NiA2LjUzOTA2IDIuNjk1MzEgNi42MzI4MUw0LjgyODEyIDguNzg5MDZDNC45NDUzMSA4LjkwNjI1IDUuMTA5MzggOC45MDYyNSA1LjIyNjU2IDguNzg5MDZMOS4yODEyNSA0Ljc4MTI1QzkuMzc1IDQuNjY0MDYgOS4zNzUgNC40NzY1NiA5LjI4MTI1IDQuMzgyODFaIiBmaWxsPSIjN0U4NDg5Ii8+Cjwvc3ZnPgo=') no-repeat center;
    position: absolute;
    left: -3px;
    top: 1px;
  }
}

.status--denied{
  &::after{
    content: '';
    width: 16px;
    height: 16px;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMC42ODc1QzIuNzg5MDYgMC42ODc1IDAuMTg3NSAzLjI4OTA2IDAuMTg3NSA2LjVDMC4xODc1IDkuNzEwOTQgMi43ODkwNiAxMi4zMTI1IDYgMTIuMzEyNUM5LjIxMDk0IDEyLjMxMjUgMTEuODEyNSA5LjcxMDk0IDExLjgxMjUgNi41QzExLjgxMjUgMy4yODkwNiA5LjIxMDk0IDAuNjg3NSA2IDAuNjg3NVpNNiAxMS4xODc1QzMuMzk4NDQgMTEuMTg3NSAxLjMxMjUgOS4xMDE1NiAxLjMxMjUgNi41QzEuMzEyNSAzLjkyMTg4IDMuMzk4NDQgMS44MTI1IDYgMS44MTI1QzguNTc4MTIgMS44MTI1IDEwLjY4NzUgMy45MjE4OCAxMC42ODc1IDYuNUMxMC42ODc1IDkuMTAxNTYgOC41NzgxMiAxMS4xODc1IDYgMTEuMTg3NVpNOC4zNjcxOSA1LjA0Njg4QzguNDg0MzggNC45NTMxMiA4LjQ4NDM4IDQuNzY1NjIgOC4zNjcxOSA0LjY0ODQ0TDcuODUxNTYgNC4xMzI4MUM3LjczNDM4IDQuMDE1NjIgNy41NDY4OCA0LjAxNTYyIDcuNDUzMTIgNC4xMzI4MUw2IDUuNTg1OTRMNC41MjM0NCA0LjEzMjgxQzQuNDI5NjkgNC4wMTU2MiA0LjI0MjE5IDQuMDE1NjIgNC4xMjUgNC4xMzI4MUwzLjYwOTM4IDQuNjQ4NDRDMy40OTIxOSA0Ljc2NTYyIDMuNDkyMTkgNC45NTMxMiAzLjYwOTM4IDUuMDQ2ODhMNS4wNjI1IDYuNUwzLjYwOTM4IDcuOTc2NTZDMy40OTIxOSA4LjA3MDMxIDMuNDkyMTkgOC4yNTc4MSAzLjYwOTM4IDguMzc1TDQuMTI1IDguODkwNjJDNC4yNDIxOSA5LjAwNzgxIDQuNDI5NjkgOS4wMDc4MSA0LjUyMzQ0IDguODkwNjJMNiA3LjQzNzVMNy40NTMxMiA4Ljg5MDYyQzcuNTQ2ODggOS4wMDc4MSA3LjczNDM4IDkuMDA3ODEgNy44NTE1NiA4Ljg5MDYyTDguMzY3MTkgOC4zNzVDOC40ODQzOCA4LjI1NzgxIDguNDg0MzggOC4wNzAzMSA4LjM2NzE5IDcuOTc2NTZMNi45MTQwNiA2LjVMOC4zNjcxOSA1LjA0Njg4WiIgZmlsbD0iI0ZGMkIyQiIvPgo8L3N2Zz4K') no-repeat center;
    position: absolute;
    left: -3px;
    top: 1px;
  }
}

.status--confirmed{
  &::after{
    content: '';
    width: 16px;
    height: 16px;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMC42ODc1QzIuNzg5MDYgMC42ODc1IDAuMTg3NSAzLjMxMjUgMC4xODc1IDYuNUMwLjE4NzUgOS43MTA5NCAyLjc4OTA2IDEyLjMxMjUgNiAxMi4zMTI1QzkuMTg3NSAxMi4zMTI1IDExLjgxMjUgOS43MTA5NCAxMS44MTI1IDYuNUMxMS44MTI1IDMuMzEyNSA5LjE4NzUgMC42ODc1IDYgMC42ODc1Wk02IDEuODEyNUM4LjU3ODEyIDEuODEyNSAxMC42ODc1IDMuOTIxODggMTAuNjg3NSA2LjVDMTAuNjg3NSA5LjEwMTU2IDguNTc4MTIgMTEuMTg3NSA2IDExLjE4NzVDMy4zOTg0NCAxMS4xODc1IDEuMzEyNSA5LjEwMTU2IDEuMzEyNSA2LjVDMS4zMTI1IDMuOTIxODggMy4zOTg0NCAxLjgxMjUgNiAxLjgxMjVaTTkuMjgxMjUgNC44ODI4MUw4Ljc0MjE5IDQuMzQzNzVDOC42NDg0NCA0LjIyNjU2IDguNDYwOTQgNC4yMjY1NiA4LjM0Mzc1IDQuMzQzNzVMNS4wMzkwNiA3LjYyNUwzLjYzMjgxIDYuMjE4NzVDMy41MTU2MiA2LjEwMTU2IDMuMzUxNTYgNi4xMDE1NiAzLjIzNDM4IDYuMjE4NzVMMi42OTUzMSA2LjczNDM4QzIuNjAxNTYgNi44NTE1NiAyLjYwMTU2IDcuMDM5MDYgMi42OTUzMSA3LjEzMjgxTDQuODI4MTIgOS4yODkwNkM0Ljk0NTMxIDkuNDA2MjUgNS4xMDkzOCA5LjQwNjI1IDUuMjI2NTYgOS4yODkwNkw5LjI4MTI1IDUuMjgxMjVDOS4zNzUgNS4xNjQwNiA5LjM3NSA0Ljk3NjU2IDkuMjgxMjUgNC44ODI4MVoiIGZpbGw9IiMyMTk2NTMiLz4KPC9zdmc+Cg==') no-repeat center;
    position: absolute;
    left: -3px;
    top: 1px;
  }
}
