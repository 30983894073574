@font-face {
    font-family: 'FuturaFuturisC';
    src: url('../assets/fonts/FuturaFuturisC.eot');
    src: url('../assets/fonts/FuturaFuturisC.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/FuturaFuturisC.woff2') format('woff2'),
    url('../assets/fonts/FuturaFuturisC.woff') format('woff'),
    url('../assets/fonts/FuturaFuturisC.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FuturaPT';
    src: url('../assets/fonts/FuturaPT-Book.eot');
    src: url('../assets/fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/FuturaPT-Book.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT-Book.woff') format('woff'),
    url('../assets/fonts/FuturaPT-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FuturaPT';
    src: url('../assets/fonts/FuturaPT-Medium.eot');
    src: url('../assets/fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/FuturaPT-Medium.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT-Medium.woff') format('woff'),
    url('../assets/fonts/FuturaPT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}