@import "../../scss/variables";

.menu {
  position: absolute;
  top: 97%;
  width: 146px;
  border-top: 2px solid $orange;
  z-index: 10;

  a {
    cursor: pointer;
  }

  &__right {
    right: -5px;
  }

  &__center {
    left: 50%;
    transform: translate(-50%, 0);
  }

  &-item {
    display: block;
    background-color: $text;
    padding: 10px 12px;
    width: 100%;
    
    &__grid {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 8px;
      align-items: center;
    }

    div {
      width: 100%;
    }

    &:not(:last-child) {
      border-bottom: 2px solid #2c313a;
    }
  }
}
