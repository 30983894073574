@import "../../scss/variables";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  &.fixed {
    position: fixed;
    background: rgba(255,255,255, 0.8);
    z-index: 100;
    top: 0;
    left: 0;

    .loader {
      z-index: 101;
    }
  }

  .loader {
    transform: rotate(0deg);
    animation: rotate .7s cubic-bezier(.3,.63,.83,.67) 0s infinite
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
