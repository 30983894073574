.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($text, 0.7);
  z-index: 10;
  &.active-search {
    z-index: 9;
    display: block;
  }
  &.active {
    display: block;
  }
}