.page {
  flex: 1 1 auto;
  padding: 16px 0 60px 0;
  @media (max-width: 990px) {
    //padding-top: 64px;
  }
  &__home{
    .container{
      max-width: 1200px;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      a, button {
        margin-top: 12px;
      }
    }
  }
  &__head-filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row !important;
  }
  &__head-search {
    @media (max-width: 1200px) {
      border-bottom: 1px solid #e0e0e0;
      padding: 0 0 20px 0;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 -32px;
      padding: 0 32px 20px 32px;
    }
  }
  &__head-shop {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &--sticky {
    @media (max-width: 768px) {
      padding: 16px 0 0 0;
      .items {
        margin-bottom: 60px;
      }
    }
  }
  &__container{
    .container{
      max-width: 1200px;
    }
  }
}