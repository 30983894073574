@import "../../../../../scss/variables";

.footer__item {
  font-family: $title;
  text-align: center;
  color: #999;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .4px;
  @media (max-width: 768px) {
    text-align: left;
  }
}
