@import "../../../../scss/variables";

.popup {
  &__message {
    background-color: $redLight;
    margin: 24px -32px;
    padding: 12px 32px;
    font-weight: 500;
    @media (max-width: 480px) {
      margin: 24px -16px;

    }
  }
}