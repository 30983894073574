.general{
  display: inline-block;
  margin-bottom: 28px;
  @media (max-width: 768px) {
    display: block;
  }
  &__order{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: 768px) {
      display: block;
    }
  }
  &__pay{
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__inner{
    display: flex;
  }
  &__btns{
    margin-top: 16px;
    display: flex;
    align-items: flex-end;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    &>a, >button {
      margin-top: 20px;
      margin-right: 24px;

    }
    .message{
      margin-top: 20px;
      max-width: 300px;
      white-space: normal;
    }
  }
  &__btns-inner{
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .point{
    display: inline-grid;
    //grid-auto-flow: column;
    //grid-template-rows: repeat(5,auto);
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 0 !important;
    @media (min-width: 768px) {
      background: #f8f8f8;
      box-shadow: none;
    }
    @media (max-width: 768px) {
      //grid-template-rows: auto;
      grid-template-columns: auto;
      grid-auto-flow: row;
    }

  }
  .point__item{
    background-color: #fff;
    @media (min-width: 768px) {
      grid-template-columns:
              minmax(180px, max-content)
              minmax(180px, max-content);
      margin: 0 16px -2px 0;
      border: 2px solid #e6e6e6;
    }
  }
  &__seller{
    width: 100%;
    .general__pay{
      margin-top: 24px;
    }
    .point{
      grid-template-columns: auto;
      grid-auto-flow: row;
    }
    .general__btns{
      width: 100%;
      justify-content: space-between;
    }
    .general__btns .message{
      max-width: 100%;
      margin-top: 0;
      white-space: normal;
    }
    .general__btns a, .general__btns button {
      margin-top: 0;
      margin-right: 0;
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
    @media (max-width: 768px) {
      .general__btns-inner{
        display: flex !important;
      }
      &.general__order .general__pay{
        display: flex !important;
      }
    }

  }
  &__upload{
    grid-area: general;
  }
  &__title{
    margin-bottom: 24px;
  }
}

.general__btns--seller {
  width: 100%;
  justify-content: space-between;

  .message{
    max-width: 100%;
    margin-top: 0;
    white-space: normal;
  }
  a, button {
    margin-top: 0;
    margin-right: 0;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
  @media (max-width: 768px) {
    .general__btns-inner{
      display: flex !important;
    }
    &.general__order .general__pay{
      display: flex !important;
    }
  }
}

.general__pay--seller-order {
  margin-top: 24px;
  @media (max-width: 768px) {
    display: flex !important;
  }
}
